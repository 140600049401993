import Header from "../components/Header";
import LoginContainer from "../containers/Login.container";

const LoginPage = () => {
  return (
    <>
      <Header />
      <LoginContainer />
    </>
  )
}

export default LoginPage;
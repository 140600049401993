import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import jwt_decode from "jwt-decode";
import useStore from '../modules/store';
import axios from "axios";

const LoginContainer = () => {
  const { REACT_APP_API_URL } = process.env;
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const setUser = useStore(state => state.setUser);

  const submitLogin = async (data) => {
    try {
      const res = await axios.post(REACT_APP_API_URL + "/api/login/isCorrectPwd", data);
      if (res.data.Result === "Success") {
        let user = {};
        const decoded = jwt_decode(res.data.token);
        user.id = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
        user.name = res.data.UserName;
        user.accessToken = res.data.token;
        // user.refreshToken = res.data.RefreshToken;
        user.exp = decoded.exp;
        setUser(user);
        navigate("/");
      } else {
        setErrMessage("아이디 또는 비밀번호가 일치하지 않습니다.")
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="login-box">
      <h1>Login</h1>
      <form onSubmit={handleSubmit(submitLogin)} autoComplete="off">
        <input type="text" {...register("userId", { required: true })} placeholder="아이디를 입력하세요." />
        <input type="password" {...register("pwd", { required: true })} placeholder="비밀번호를 입력하세요." />
        <div className="err-message">{errMessage}</div>
        <button type="submit" className="button full round yellow">로그인</button>
      </form>
    </div>
  )
}

export default LoginContainer
import React from 'react';

const Write = () => {
  return (
    <div className='board'>
      <div className="post-write">
        <form>
          <div className='input-box'>
            <h3>제목</h3>
            <input type="text" placeholder='subject' />
          </div>
          <div className='input-box'>
            <h3>내용</h3>
            <textarea></textarea>
          </div>
          <div className="btn-box">
            <button type='submit'>등록</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Write
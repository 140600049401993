import Header from "../components/Header";
import CalendarContainer from "../containers/Calendar.container";

const CalendarPage = () => {
  return (
    <>
      <Header />
      <CalendarContainer />
    </>
  )
}

export default CalendarPage
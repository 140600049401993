import Header from "../components/Header";
import ConferenceContainer from "../containers/Conference.container";

const ConferencePage = () => {
  return (
    <>
      <Header />
      <ConferenceContainer />
    </>
  )
}

export default ConferencePage;
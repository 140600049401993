import { Link } from "react-router-dom";
import useStore from '../modules/store';
import axios from "axios";
import { FiCalendar, FiUsers, FiChevronsUp, FiFileText, FiLogOut, FiGift } from "react-icons/fi";

const Header = () => {
  const user = useStore(state => state.user);
  const logout = useStore(state => state.logout);

  const onLogout = () => {
    // axios.post(REACT_APP_API_URL + "/api/logout", data);
    logout();
  }

  return (
    <div className="header">
      {/* <Link to="/schedule" className="button"><FiChevronsUp /> 일정</Link> */}
      <Link to="/" className="button"><FiCalendar /> 연차</Link>
      <Link to="/birthday" className="button"><FiGift /> 생일</Link>
      <Link to="/conference" className="button"><FiUsers /> 회의실</Link>
      <Link to="/board" className="button"><FiFileText /> 게시판</Link>
      {user && 
        <div className="logout">
          <div className="name">{user.name}</div>
          <button type="button" onClick={onLogout} ><FiLogOut /></button>
        </div>
      }
    </div>
  )
}

export default Header
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const BirthdayContainer = () => {
  const events = [
    { title: "김선희", date: "2022-05-24" },
    { title: "김기배", date: "2022-05-26" },
    { title: "이종찬", date: "2022-06-10" },
    { title: "김단영", date: "2022-06-25" },
    { title: "최주혜", date: "2022-06-28" },
    { title: "박원철", date: "2022-07-29" },
    { title: "유넷", date: "2022-10-05" },
    { title: "전홍찬", date: "2022-11-07" },
    { title: "김대원", date: "2022-12-01" },
    { title: "이선미", date: "2022-12-05" },
  ]

  return (
    <div className="container">
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        locale="ko"
        events={events}
        eventColor={"#E57373"}
      />
    </div>
  )
}

export default BirthdayContainer
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import useStore from '../modules/store';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Loader from "../components/common/Loader";

const CalendarContainer = () => {
  const { REACT_APP_API_URL } = process.env;
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const accessToken = useStore(state => state.user.accessToken);

  const getList = async () => {
    try {
      const res = await axios.get(REACT_APP_API_URL + '/api/HolidayCalendar', {
        headers: { authorization: 'Bearer ' + accessToken }
      });
      const data = res.data.map((item) => {
        item["EndDt"] = (parseInt(item["EndDt"]) + 1).toString();
        let newObject = {};
        newObject["title"] = item["korNm"];
        newObject["start"] = item["StartDt"];
        newObject["end"] = item["EndDt"];
        switch (item.HolidayType) {
          case "오전반차":
            newObject["title"] += " - 오전 반차";
            break;
          case "오후반차":
            newObject["title"] += " - 오후 반차";
            break;
          default:
            newObject["title"] += " - 연차";
        }
        return newObject;
      });
      setIsLoading(false);
      setEvents(data);
    } catch (err) {
      console.log(err);
    }
  };

  // Location Refresh TEST
  useEffect(() => {
    if (accessToken) {
      getList();
    }
  }, [location]);

  // useEffect(() => {
  //   if (token) {
  //     getList();
  //   }
  // }, [token]);

  return (
    <div className="container">
      {isLoading && <Loader />}
      {events && (
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          locale="ko"
          events={events}
          eventColor={"#66BB6A"}
        />
      )}
    </div>
  )
}

export default CalendarContainer
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import useStore from './modules/store';
import CalendarPage from './pages/Calendar.page';
import ConferencePage from './pages/Conference.page';
import LoginPage from './pages/Login.page';
import SchedulePage from './pages/Schedule.page';
import BoardListPage from './pages/board/List.page';
import BoardDetailPage from './pages/board/Detail.page';
import BoardWritePage from './pages/board/Write.page';
import BirthdayPage from './pages/Birthday.page';
import "./App.css";

function App() {
  const user = useStore(state => state.user);
  const logout = useStore(state => state.logout);
  const location = useLocation();

  useEffect(() => {
    if (user?.name && user?.exp < Math.floor(new Date().getTime() / 1000)) {
      logout();
    }
  }, [location]);

  return (
    <div className="app">
      {user?.name ?
        <Routes>
          <Route path="/" element={<CalendarPage />} />
          <Route path="/conference" element={<ConferencePage />} />
          <Route path="/schedule" element={<SchedulePage />} />
          <Route path="/board" element={<BoardListPage />} />
          <Route path="/board/:id" element={<BoardDetailPage />} />
          <Route path="/board/write" element={<BoardWritePage />} />
          <Route path="/birthday" element={<BirthdayPage />} />
        </Routes>
        :
        <Routes>
          <Route path="/*" element={<LoginPage />} />
        </Routes>
      }
    </div>
  );
}

export default App;

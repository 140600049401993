import Header from "../../components/Header";
import BoardListContainer from "../../containers/board/List.container";

const BoardListPage = () => {
  return (
    <>
      <Header />
      <BoardListContainer />
    </>
  )
}

export default BoardListPage;
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import DatePicker from 'react-datepicker';
import { format } from "date-fns";
import { ko } from "date-fns/esm/locale";
import { FiEdit, FiPlus, FiTrash2, FiX } from "react-icons/fi";
import Loader from "../components/common/Loader";
import 'react-datepicker/dist/react-datepicker.css';

const ConferenceContainer = () => {
  const { REACT_APP_API_URL } = process.env;
  const { register, handleSubmit } = useForm();
  const [events, setEvents] = useState([]);
  const [myEvents, setMyEvents] = useState([]);
  const [subject, setSubject] = useState();
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [modal, setModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // get Events
  const getEvents = async () => {
    try {
      const res = await axios.get(REACT_APP_API_URL + "/api/conference");
      console.log(res.data);
      // setIsLoading(false);
      // setEvents(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  
  useEffect(() => {
    // getEvents();
    setEvents([
      {
        title: '연구개발팀',
        start: '2022-03-22 10:00:00',
        end: '2022-03-22 12:00:00'
      },
      {
        title: '디자인팀',
        start: '2022-03-23 13:00:00'
      }
    ]);
    // setMyEvents();
  }, []);

  // open Modal
  const openModal = (type) => {
    setModal(type);
  }

  // close Modal
  const closeModal = () => {
    setModal(null);
  }
  
  // change Subject
  const changeSubject = (e) => {
    setSubject(e.target.value);
  }

  // change Start
  const changeStart = (date) => {
    setStart(date);
  }
  
  // change End
  const changeEnd = (date) => {
    setEnd(date);
  }
  
  // submit Write
  const sumitWrite = () => {
    const startTime = format(start, "yyyy-MM-dd HH:mm:ss");
    const endTime = format(end, "yyyy-MM-dd HH:mm:ss");
    setEvents(events.concat({
      title: subject,
      start: startTime,
      end: endTime
    }));
    setModal(null);
  }
  
  return (
    <div className="container conference">
      {isLoading && <Loader />}
      {events && <FullCalendar
        plugins={[timeGridPlugin]}
        initialView="timeGridWeek"
        timeZone='UTC'
        allDaySlot={false}
        slotMinTime="09:00:00"
        slotMaxTime="19:00:00"
        locale="ko"
        events={events}
        eventColor={"#66BB6A"}
      />}
      {modal === "write" &&
        <div className="modal write-modal">
          <div className="top">
            <h1>등록하기</h1>
            <button type="button" className="close" onClick={closeModal}>
              <FiX />
            </button>
          </div>
          <div className="content">
            <label>제목</label>
            <input type="text" onChange={changeSubject} />
            <label>시작 시간</label>
            <DatePicker
              locale={ko}
              dateFormat="yyyy-MM-dd HH:mm:ss"
              showTimeSelect
              timeIntervals={10}
              onChange={date => changeStart(date)}
              selected={start}
            />
            <label>종료 시간</label>
            <DatePicker
              locale={ko}
              dateFormat="yyyy-MM-dd HH:mm:ss"
              showTimeSelect
              timeIntervals={10}
              onChange={date => changeEnd(date)}
              selected={end}
            />
            <div className="btn-group">
              <button type="button" className="cancel" onClick={closeModal}>취소</button>
              <button type="button" className="submit" onClick={sumitWrite}>등록</button>
            </div>
          </div>
        </div>
      }
      {modal === "edit" && 
        <div className="modal edit-modal">
          <div className="top">
            <h1>리스트</h1>
            <button type="button" className="close" onClick={closeModal}>
              <FiX />
            </button>
          </div>
          <div className="content scroll">
            <table>
              <colgroup>
                <col width="33%" />
                <col width="25%" />
                <col width="4%" />
                <col width="25%" />
                <col width="13%" />
              </colgroup>
              <tbody>
              {/* {myEvents ? */}
                <tr>
                  <td>연구개발팀 회의</td>
                  <td>2022-03-21 14:00</td>
                  <td>~</td>
                  <td>2022-03-21 15:00</td>
                  <td>
                    <button type="button" className="delete">
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
                <tr>
                <td>연구개발팀 회의</td>
                  <td>2022-03-21 14:00</td>
                  <td>~</td>
                  <td>2022-03-21 15:00</td>
                  <td>
                    <button type="button" className="delete">
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>연구개발팀 회의</td>
                  <td>2022-03-21 14:00</td>
                  <td>~</td>
                  <td>2022-03-21 15:00</td>
                  <td>
                    <button type="button" className="delete">
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>연구개발팀 회의</td>
                  <td>2022-03-21 14:00</td>
                  <td>~</td>
                  <td>2022-03-21 15:00</td>
                  <td>
                    <button type="button" className="delete">
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>연구개발팀 회의</td>
                  <td>2022-03-21 14:00</td>
                  <td>~</td>
                  <td>2022-03-21 15:00</td>
                  <td>
                    <button type="button" className="delete">
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>연구개발팀 회의</td>
                  <td>2022-03-21 14:00</td>
                  <td>~</td>
                  <td>2022-03-21 15:00</td>
                  <td>
                    <button type="button" className="delete">
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
              {/* } */}
              </tbody>
            </table>
          </div>
        </div>
      }
      <button type="button" className="fixed-button edit" onClick={() => openModal('edit')}>
        <FiEdit />
      </button>
      <button type="button" className="fixed-button write" onClick={() => openModal("write")}>
        <FiPlus />
      </button>
    </div>
  )
}

export default ConferenceContainer;
import Header from "../../components/Header";
import BoardWriteContainer from "../../containers/board/Write.container";

const WritePage = () => {
  return (
    <>
      <Header />
      <BoardWriteContainer />
    </>
  )
}

export default WritePage
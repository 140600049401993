import Header from "../components/Header";
import ScheduleContainer from "../containers/Schedule.container";

const SchedulePage = () => {
  return (
    <>
      <Header />
      <ScheduleContainer />
    </>
  )
}

export default SchedulePage;
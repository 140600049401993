import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import List from "../../components/board/List";
import Loader from "../../components/common/Loader";
import { FiPlus } from "react-icons/fi";

const ListContainer = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPosts = async () => {
    setIsLoading(true);
    const res = await axios.get('https://jsonplaceholder.typicode.com/posts');
    setPosts(res.data);
    setIsLoading(false);
  }

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <div className="container">
      {isLoading
        ? <Loader />
        : <List posts={posts} />
      }
      <button type="button" className="fixed-button write">
        <Link to='/board/write'>
          <FiPlus />
        </Link>
      </button>
    </div>
  )
}

export default ListContainer
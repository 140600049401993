import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

let store = (set) => ({
  user: {
    id: null,
    name: null,
    accessToken: null,
    refreshToken: null,
    exp: null
  },
  setUser: (user) => set(() => ({ user })),
  logout: () => set({ user: null }),
});

store = devtools(store);
store = persist(store, { name: 'auth', getStorage: () => localStorage });

const useStore = create(store);

export default useStore;
import { Link } from "react-router-dom";

const List = ({ posts }) => {
  return (
    <div className='board'>
      <div className='post-list'>
        <ul>
          {posts && posts.map((post) => (
            <li key={post.id}>
              <Link to={`/board/${post.id}`}>{post.title}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="pagination">
        1 2 3
      </div>
    </div>
  )
}

export default List
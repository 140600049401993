import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Detail from "../../components/board/Detail";
import Loader from "../../components/common/Loader";

const DetailContainer = () => {
  const { id } = useParams();
  const [post, setPost] = useState([]);
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPost = async () => {
    setIsLoading(true);
    const resPost = await axios.get(`https://jsonplaceholder.typicode.com/posts/${id}`);
    const resComments = await axios.get(`https://jsonplaceholder.typicode.com/posts/${id}/comments`);
    setPost(resPost.data);
    setComments(resComments.data);
    setIsLoading(false);
  }

  useEffect(() => {
    getPost();
  }, []);
  
  return (
    <div className="container">
      {isLoading
        ? <Loader />
        : <Detail post={post} comments={comments} />
      }
    </div>
  )
}

export default DetailContainer
const Detail = ({ post, comments }) => {
  return (
    <div className="board">
      <div className="post-detail">
        <h1>{post.title}</h1>
        <p>{post.body}</p>
        <div className="comments">
          <ul>
            {comments.map((comment) => (
              <li key={comment.id}>
                <div className="name">
                  {comment.name}
                </div>
                <div className="content">
                  {comment.body}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Detail
import React, { useState, useEffect } from 'react'
import useStore from '../modules/store';
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { format } from "date-fns";
import Loader from "../components/common/Loader";

const ScheduleContainer = () => {
  const CLICKUP_ACCESS_TOKEN = useStore(state => state.clickupToken);
  const user_name = useStore(state => state.userName);
  const [activeSpace, setActiveSpace] = useState();
  const [spaces, setSpaces] = useState([]);
  const [folders, setFolders] = useState([]);
  const [lists, setLists] = useState([]);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Get Spaces
  const getSpaces = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get("/api/v2/team/5728795/space?archived=false", {
        headers: {
          "Authorization": CLICKUP_ACCESS_TOKEN
        }
      });
      setSpaces(res.data.spaces);
      setFolders('');
      setLists('');
      setEvents('');
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  // Get Folders
  const getFolders = async (id, name) => {
    setActiveSpace(name);
    setIsLoading(true);
    try {
      const res = await axios.get(`/api/v2/space/${id}/folder?archived=false`, {
        headers: {
          "Authorization": CLICKUP_ACCESS_TOKEN
        }
      });
      setFolders(res.data.folders);
      setLists('');
      setEvents('');
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Get Lists
  const getLists = async (id) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`/api/v2/folder/${id}/list?archived=false`, {
        headers: {
          "Authorization": CLICKUP_ACCESS_TOKEN
        }
      });
      setLists(res.data.lists);
      setEvents('');
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Get Tasks
  const getTasks = async (id) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`/api/v2/list/${id}/task?archived=false`, {
        headers: {
          "Authorization": CLICKUP_ACCESS_TOKEN
        }
      });
      const data = res.data.tasks.map((item) => {
        let newObject = {};

        let assign = [];
        item.assignees.map((a) => {
          assign.push(a.username);
        });

        // Assign Check
        if (!assign.includes(user_name)) {
          return newObject;
        }

        // Event Data Create
        newObject["title"] = item["name"];
        newObject["url"] = item["url"];
        item["start_date"] === null 
          ? newObject["start"] = format(new Date(), "yyyy-MM-dd") 
          : newObject["start"] = format(new Date(parseInt(item["start_date"])), "yyyy-MM-dd");
        item["due_date"] === null 
          ? newObject["end"] = format(new Date(), "yyyy-MM-dd") 
          : newObject["end"] = format(new Date(parseInt(item["due_date"]) + 86400000), "yyyy-MM-dd");

        return newObject;
      });
      setEvents(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Event Click
  const eventClick = (event) => {
    event.jsEvent.preventDefault();
    window.open(event.event.url, "_blank");
    return false;
  }

  useEffect(() => {
    getSpaces();
  }, []);

  return (
    <div className='container schedule'>
      {isLoading && <Loader />}
      <div className="space">
        {spaces && spaces.map((space) => (
          <button type='button' className={activeSpace === space.name ? 'active': null} onClick={() => getFolders(space.id, space.name)} key={space.id}>{space.name}</button>
          ))}
      </div>
      <div className="folder">
        {folders && folders.map((folder) => (
          <button type='button' onClick={() => getLists(folder.id)} key={folder.id}>{folder.name}</button>
        ))}
      </div>
      <div className="list">
        {lists && lists.map((list) => (
          <button type='button' onClick={() => getTasks(list.id)} key={list.id}>{list.name}</button>
        ))}
      </div>
      <div className="calendar">
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          locale="ko"
          events={events}
          eventColor={"#66BB6A"}
          eventClick={(event) => eventClick(event)}
        />
      </div>
    </div>
  )
}

export default ScheduleContainer;
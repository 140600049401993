import { BallTriangle } from  'react-loader-spinner';

const Loader = () => {
  return (
    <div className='loader'>
      <BallTriangle color="#FDD835" height={80} width={80} />
    </div>
  )
}

export default Loader;